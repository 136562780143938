import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Logo from '../../Assets/img/logo.svg';

export default function Footer() {
  const currentURL = window.location.origin;

  return (
    <footer>
      <div className="container">
        <Row className="mb-5 g-4">
          <Col lg={3} md={6}>
            <div className="footer_link">
              <ul>
                <li>
                  T:<Link to="tel:01932359900">01932 359900</Link>
                </li>
                <li>
                  E:
                  <Link to="mailto:sales@bestpartiesever.com">
                    sales@bestpartiesever.com
                  </Link>
                </li>
                <li>
                  <p className="full_address">
                    Units 3-4 Trade City, Avro Way Brooklands Industrial Estate
                    Weybridge SURREY KT13 0YF
                  </p>
                </li>
                <li>
                  <h4>FOLLOW US</h4>
                </li>
                <li>
                  <div className="social_icon_footer">
                    <ul>
                      <li>
                        <Link
                          to="https://www.facebook.com/BestPartiesEver"
                          target="_blank"
                          aria-label="Facebook"
                        >
                          <i className="pi pi-facebook"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.instagram.com/bestpartieseveruk/"
                          target="_blank"
                          aria-label="Instagram"
                        >
                          <i className="pi pi-instagram"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://twitter.com/BestPartiesEver"
                          target="_blank"
                          aria-label="Twitter"
                        >
                          <i className="pi pi-twitter"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.linkedin.com/company/best-parties-ever-limited/?originalSubdomain=uk"
                          target="_blank"
                          aria-label="Linkedin"
                        >
                          <i className="pi pi-linkedin"></i>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="https://www.tiktok.com/@bestpartiesever?lang=en"
                          target="_blank"
                          aria-label="Tiktok"
                        >
                          <i className="fab fa-tiktok"></i>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="footer_link">
              <h4>INFORMATION</h4>
              <ul>
                <li>
                  <Link
                    to="https://bestpartiesever.com/about-us/"
                    target="_self"
                  >
                    About us
                  </Link>
                </li>
                <li>
                  <Link
                    to={`${currentURL}/request-brochures`}
                    reloadDocument
                    target="_blank"
                  >
                    Request a Brochure
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://bestpartiesever.com/faqs-page/"
                    target="_self"
                  >
                    FAQ's
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://bestpartiesever.com/booking-terms/"
                    target="_self"
                  >
                    Booking Terms
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://bestpartiesever.com/privacy/"
                    target="_self"
                  >
                    Privacy &amp; Cookies
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://bestpartiesever.com/sustainability/"
                    target="_self"
                  >
                    Sustainability
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="footer_link">
              <h4 className="text-uppercase">Account</h4>
              <ul>
                <li>
                  <Link
                    to={`${currentURL}/account`}
                    reloadDocument
                    target="_blank"
                  >
                    Manage Booking
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://bestpartiesever.com/feedback/"
                    target="_self"
                  >
                    Feedback
                  </Link>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={3} md={6}>
            <div className="footer_link">
              <ul>
                <li>
                  <div className="footer_logo">
                    <img src={Logo} alt="Logo" />
                  </div>
                </li>
                <li>
                  <div className="copyright_text">
                    <p>
                      © 2015-{new Date().getFullYear()} Eventist Group | All
                      Rights Reserved
                    </p>
                    <p>
                      Eventist Group is a limited company trading as Best
                      Parties Ever. Registered in England. Registration Number:
                      3209530 <br />
                      Registered Office: Units 3-4 Trade City, Avro Way,
                      Brooklands Industrial Estate, Weybridge, Surrey, KT13 0YF.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </div>
    </footer>
  );
}
